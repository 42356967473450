<template>
  <div class="root">
    <div class="child" :class="{ 'light-mode': isLightMode }">
      <router-view></router-view>
    </div>
  </div>
</template>


<script>


import {getTheme} from "@/utils/HeadTool";

export default {
  name: 'App',
  components: {
    //Mchat,
  },


  mounted: function () {
    let isLightStr = getTheme()
    if (isLightStr === undefined) {
      this.isLightMode = false
    } else {

      this.isLightMode = isLightStr === "true"

    }
    this.$router.push({path: "/pages/mchat"});
  },

  provide() {
    return {
      app: this
    };
  },

  data() {
    return {
      isLightMode : false
    }
  },


  methods: {
  }
}
</script>

<style>
#app {
  font-family: 'Microsoft YaHei',  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
}

html,
body {
  margin: 0;
  padding: 0;
}

.root {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
}




::-webkit-scrollbar {
  width: 6px !important;
  height: 8px !important;
}


.child {
  width: 100%;
  height: 100%;
  flex: 1;
}

:root {
  --mchat-bg-color: rgba(50, 51, 42, 1);
  --mchat-loading-color: rgba(50, 51, 42, 0.9);

  --mchat-tools-color: rgba(77, 79, 70, 1);
  --mchat-tools-color-light: rgba(245, 245, 245, 1);

  --mchat-tools-hover-color: rgba(97, 99, 90, 1);
  --mchat-tools-hover-color-light: rgb(238, 239, 234);

  --mchat-scrollbar-color: rgba(204, 204, 204, 1);
  --mchat-scrollbar-hover-color:  #555; /* 鼠标悬停时滚动条的颜色 */
  --mchat-lighter-line-color: rgb(113, 113, 104);
  --mchat-lighter-text-color: #a6a6a6;
  --mchat-message-send-bg-color: rgba(213, 227, 145, 1);
  --mchat-message-send-txt-color: rgba(50, 51, 42, 1);
  --mcaht-message-received-bg-color: rgba(77, 79, 70, 1);
  --mcaht-message-received-txt-color: rgba(243, 245, 235, 1);
  --mchat-input-placeholder-txt-color: rgba(166, 166, 166, 1);
  --mchat-hint-txt-color: rgba(247, 250, 235, 1);
  --mchat-input-txt-color:#F3F5EB;
  --mchat-text-color: rgba(243, 245, 235, 1);
  --mchat-text-color-light: rgba(50, 51, 42, 1);

  --mchat-switch-bg-color: rgba(97, 99, 90, 1);


  --mchat-vip-bg-color: rgba(37, 38, 30, 1);
  --mchat-vip-button1-color: rgba(255, 255, 255, 0.1);

  --mchat-vip-order-button-color:rgba(37, 38, 30, 1);
  --mchat-vip-order-button-selected-color: rgba(35, 36, 28, 1);
  --mchat-vip-order-button-hover-color: rgba(77, 78, 70, 1);

  --mchat-vip-cancel-button-color: #f3f5eb;
  --mchat-vip-cancel-button-hover-color: rgba(220, 222, 210, 1);


  --mchat-vip-choose-button-color: #f3f5eb;
  --mchat-vip-choose-button-hover-color: rgba(220, 222, 210, 1);


  --mchat-md-table-color: #3d403a;
  --mchat-border-color: #f3f5eb;

  --mchat-box-shadow: none;
  --mchat-modal-bg-color: rgba(50, 51, 42, 0.5);

  --mchat-menu-item-hover-color: rgba(255, 255, 255, 0.08);

  --mchat-history-color: rgba(77, 79, 70, 1);



  --hue: 223;
  --bg: hsl(var(--hue),10%,100%);
  --fg: hsl(var(--hue),10%,0%);
  --primary: hsl(var(--hue),90%,55%);
  --primaryT: hsla(var(--hue),90%,55%,0);
  --transDur: 0.3s;

}

.child.light-mode {
  width: 100%;
  height: 100%;
  flex: 1;
  --mchat-bg-color: rgba(255, 255, 255, 1);
  --mchat-loading-color: rgba(255, 255, 255, 0.9);
  --mchat-tools-color: rgba(245, 245, 245, 1);
  --mchat-tools-hover-color: rgb(238, 239, 234);
  --mchat-scrollbar-color: rgba(204, 204, 204, 1);
  --mchat-scrollbar-hover-color:  #0a0000; /* 鼠标悬停时滚动条的颜色 */
  --mchat-lighter-line-color: #f5f5f5;
  --mchat-lighter-text-color: #4d4f46;
  --mchat-message-send-bg-color: rgba(213, 227, 145, 1);
  --mchat-message-send-txt-color: rgba(50, 51, 42, 1);
  --mcaht-message-received-bg-color: rgba(245, 245, 245, 1);
  --mcaht-message-received-txt-color: rgba(50, 51, 42, 1);
  --mchat-input-placeholder-txt-color: rgba(166, 166, 166, 1);
  --mchat-hint-txt-color:rgba(50, 51, 42, 1);
  --mchat-input-txt-color:rgba(50, 51, 42, 1);
  --mchat-text-color: rgba(50, 51, 42, 1);

  --mchat-switch-bg-color: rgb(226, 221, 221);

  --mchat-vip-bg-color: rgba(255, 255, 255, 1);
  --mchat-vip-button1-color: rgba(245, 245, 245, 1);

  --mchat-vip-order-button-color: rgba(245, 245, 245, 1);
  --mchat-vip-order-button-selected-color: rgba(255, 255, 255, 1);
  --mchat-vip-order-button-hover-color: rgba(220, 222, 210, 1);

  --mchat-vip-button2-selected-color: rgba(35, 36, 28, 1);

  --mchat-vip-cancel-button-color: rgba(245, 245, 245, 1);
  --mchat-vip-cancel-button-hover-color: rgba(220, 222, 210, 1);

  --mchat-vip-choose-button-color: rgba(213, 227, 145, 1);
  --mchat-vip-choose-button-hover-color: rgb(162, 177, 94);


  --mchat-md-table-color: rgb(238, 239, 234);


  --mchat-border-color: rgb(165, 165, 158);


  --mchat-box-shadow: 0px 2px 1px  rgba(0, 0, 0, 0.25);

  --mchat-modal-bg-color:  rgba(245, 245, 245, 0.9);

  --mchat-menu-item-hover-color: rgb(238, 239, 234);

  --mchat-history-color: rgba(255, 255, 255, 1);


}

</style>
